import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Settings from './pages/Settings';
import Calendar from './pages/Calendar';
import Posts from './pages/Posts';
import GeneratePost from './pages/GeneratePost';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.css';
import { jwtDecode } from "jwt-decode";
import GenerateBlog from './pages/GenerateBlog';

function PrivateRoute({ children, adminRequired = false }) {
  const token = localStorage.getItem('token');
  
  if (!token) {
    return <Navigate to="/login" />;
  }

  const decodedToken = jwtDecode(token);
  const isAdmin = decodedToken.is_admin;

  if (adminRequired && !isAdmin) {
    return <Navigate to="/" />;
  }

  return children;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <div className="app">
                <Sidebar />
                <div className="main-content">
                  <TopBar />
                  <div className="page-content">
                    <Routes>
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/calendar" element={<Calendar />} />
                      <Route path="/posts" element={<Posts />} />
                      <Route path="/generate" element={<GeneratePost />} />
                      <Route path="/generate-blog" element={<GenerateBlog />} />
                      <Route 
                        path="/admin" 
                        element={
                          <PrivateRoute adminRequired={true}>
                            <AdminDashboard />
                          </PrivateRoute>
                        } 
                      />
                      <Route path="/" element={<Navigate to="/generate" replace />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;