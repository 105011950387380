import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Plus, X, Trash2, Edit2 } from 'lucide-react';
import { API_BASE_URL } from '../config';

function AdminDashboard() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [editUsername, setEditUsername] = useState('');
  const [editIsAdmin, setEditIsAdmin] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage('Failed to fetch users');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (editingUser.id) {
        await axios.put(`${API_BASE_URL}/admin/users/${editingUser.id}`, {
          username: editUsername,
          is_admin: editIsAdmin
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMessage('User updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/admin/users`, {
          username,
          password,
          is_admin: isAdmin
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMessage('User created successfully');
      }
      fetchUsers();
      setEditingUser(null);
      setUsername('');
      setPassword('');
      setIsAdmin(false);
    } catch (error) {
      console.error('Error submitting user:', error);
      setMessage('Failed to submit user');
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setEditUsername(user.username);
    setEditIsAdmin(user.is_admin);
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.delete(`${API_BASE_URL}/admin/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Delete response:', response.data);
        setMessage('User deleted successfully');
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        setMessage('Failed to delete user');
      }
    }
  };

  return (
    <div className="admin-dashboard">
      <div className="admin-content card">
        <h1 className="page-title">Admin Dashboard</h1>
        <div className="section-header">
          <h2>User Management</h2>
          <button className="add-user-button" onClick={() => setEditingUser({})}>
            <Plus size={16} />
            Add User
          </button>
        </div>
        <div className="user-list-section">
          <div className="user-table-container">
            <table className="user-table">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                    <td>{user.is_admin ? 'Admin' : 'User'}</td>
                    <td>
                      <button className="icon-button edit-button" onClick={() => handleEditUser(user)}>
                        <Edit2 size={16} />
                      </button>
                      <button className="icon-button delete-button" onClick={() => handleDeleteUser(user.id)}>
                        <Trash2 size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {editingUser && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <h2>{editingUser.id ? 'Edit User' : 'Add New User'}</h2>
              <button className="close-button" onClick={() => setEditingUser(null)}><X size={20} /></button>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    id="username"
                    type="text"
                    value={editingUser.id ? editUsername : username}
                    onChange={(e) => editingUser.id ? setEditUsername(e.target.value) : setUsername(e.target.value)}
                    required
                  />
                </div>
                {!editingUser.id && (
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required={!editingUser.id}
                    />
                  </div>
                )}
                <div className="form-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={editingUser.id ? editIsAdmin : isAdmin}
                      onChange={(e) => editingUser.id ? setEditIsAdmin(e.target.checked) : setIsAdmin(e.target.checked)}
                    />
                    Admin User
                  </label>
                </div>
                <div className="modal-actions">
                  <button type="button" className="cancel-button" onClick={() => setEditingUser(null)}>Cancel</button>
                  <button type="submit" className="save-button">
                    {editingUser.id ? 'Update User' : 'Create User'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {message && <div className="message">{message}</div>}
    </div>
  );
}

export default AdminDashboard;