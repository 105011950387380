import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Mic, MicOff } from 'lucide-react';

function Settings() {
  const [businessInfo, setBusinessInfo] = useState('');
  const [language, setLanguage] = useState('EN/US');
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    fetchSettings();
    initializeSpeechRecognition();
  }, [initializeSpeechRecognition]);

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/settings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBusinessInfo(response.data.businessInfo || '');
      setLanguage(response.data.language || 'EN/US');
    } catch (error) {
      console.error('Error fetching settings:', error.response ? error.response.data : error.message);
      setMessage('Failed to fetch settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    setMessage('');
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_BASE_URL}/settings`, 
        { businessInfo, language },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessage('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error.response ? error.response.data : error.message);
      setMessage('Failed to save settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const initializeSpeechRecognition = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = getLanguageCode(language);

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setBusinessInfo(prevInfo => prevInfo + ' ' + transcript);
      };

      setRecognition(recognition);
    }
  };

  const getLanguageCode = (lang) => {
    const languageCodes = {
      'EN/US': 'en-US',
      'EN/GB': 'en-GB',
      'FR/FR': 'fr-FR',
      'DE/DE': 'de-DE',
      'IT/IT': 'it-IT',
      'ES/ES': 'es-ES',
    };
    return languageCodes[lang] || 'en-US';
  };

  const toggleListening = () => {
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  if (isLoading) {
    return <div className="settings-page"><div className="settings-content card">Loading settings...</div></div>;
  }

  return (
    <div className="settings-page">
      <div className="settings-content card">
        <h1 className="page-title">Settings</h1>
        <div className="form-group">
          <label htmlFor="businessInfo">Business Information</label>
          <p className="form-description">Enter your business information below. This will be used to personalise your generated content.</p>
          <div className="input-with-icon">
            <textarea
              id="businessInfo"
              value={businessInfo}
              onChange={(e) => setBusinessInfo(e.target.value)}
              placeholder="Enter your business information here..."
              rows="6"
              className="form-control"
            />
            <button 
              onClick={toggleListening} 
              className="icon-button"
              title={isListening ? "Stop voice input" : "Start voice input"}
            >
              {isListening ? 
                <MicOff size={18} style={{ color: 'red' }} /> : 
                <Mic size={18} style={{ color: 'black' }} />
              }
            </button>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="language">Content Language</label>
          <select
            id="language"
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
              if (recognition) {
                recognition.lang = getLanguageCode(e.target.value);
              }
            }}
            className="form-control"
          >
            <option value="EN/US">American English (EN/US)</option>
            <option value="EN/GB">British English (EN/GB)</option>
            <option value="FR/FR">French (FR/FR)</option>
            <option value="DE/DE">German (DE/DE)</option>
            <option value="IT/IT">Italian (IT/IT)</option>
            <option value="ES/ES">Spanish (ES/ES)</option>
          </select>
        </div>
        <button 
          className="save-button" 
          onClick={handleSaveSettings}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Settings'}
        </button>
        {message && <p className={message.includes('success') ? 'success-message' : 'error-message'}>{message}</p>}
      </div>
    </div>
  );
}

export default Settings;