import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { User, Lock, Cpu } from 'lucide-react';
import { API_BASE_URL } from '../config';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/generate');
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  useEffect(() => {
    const canvas = document.getElementById('orbs-canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const orbs = [];
    const orbCount = 15;

    for (let i = 0; i < orbCount; i++) {
      orbs.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      orbs.forEach(orb => {
        ctx.beginPath();
        ctx.arc(orb.x, orb.y, orb.radius, 0, Math.PI * 2);
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.fill();

        orb.x += orb.dx;
        orb.y += orb.dy;

        if (orb.x < 0 || orb.x > canvas.width) orb.dx = -orb.dx;
        if (orb.y < 0 || orb.y > canvas.height) orb.dy = -orb.dy;
      });
    }

    animate();

    // Update the glow animation
    const style = document.createElement('style');
    style.textContent = `
      @keyframes flicker1 {
        0%, 100% { opacity: 0.05; }
        25% { opacity: 0.15; }
        50% { opacity: 0.10; }
        75% { opacity: 0.20; }
      }
      @keyframes flicker2 {
        0%, 100% { opacity: 0.08; }
        33% { opacity: 0.18; }
        66% { opacity: 0.12; }
      }
      @keyframes flicker3 {
        0%, 100% { opacity: 0.06; }
        20% { opacity: 0.14; }
        40% { opacity: 0.10; }
        60% { opacity: 0.16; }
        80% { opacity: 0.08; }
      }
    `;
    document.head.appendChild(style);

    return () => {
      cancelAnimationFrame(animate);
      document.head.removeChild(style);
    };
  }, []);

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: 'black',
      backgroundImage: 'radial-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px)',
      backgroundSize: '50px 50px',
      position: 'relative',
    },
    canvas: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    formContainer: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      padding: '40px',
      borderRadius: '8px',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      width: '100%',
      maxWidth: '350px',
      boxSizing: 'border-box',
      zIndex: 1,
      position: 'relative',
    },
    title: {
      fontSize: '24px',
      fontWeight: '600',
      color: '#ffffff',
      marginBottom: '8px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subtitle: {
      fontSize: '14px',
      color: '#999',
      marginBottom: '24px',
      textAlign: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    inputGroup: {
      position: 'relative',
    },
    input: {
      width: '100%',
      padding: '12px 12px 12px 40px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '4px',
      fontSize: '14px',
      color: '#fff',
      boxSizing: 'border-box',
    },
    icon: {
      position: 'absolute',
      left: '12px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#666',
    },
    button: {
      backgroundColor: '#ffffff',
      color: '#000000',
      border: 'none',
      padding: '12px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '600',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    footer: {
      marginTop: '24px',
      textAlign: 'center',
      fontSize: '12px',
      color: '#666',
    },
    error: {
      color: '#ff4d4f',
      marginBottom: '16px',
      textAlign: 'center',
      fontSize: '14px',
    },
    formContainerWrapper: {
      position: 'relative',
      width: '100%',
      maxWidth: '350px',
    },
    glowEffect1: {
      position: 'absolute',
      top: '-6px',
      left: '-6px',
      right: '-6px',
      bottom: '-6px',
      borderRadius: '14px',
      background: 'linear-gradient(45deg, #00ff00, #0000ff)',
      filter: 'blur(10px)',
      opacity: 0.15,
      animation: 'flicker1 0.7s infinite',
      zIndex: 0,
    },
    glowEffect2: {
      position: 'absolute',
      top: '-4px',
      left: '-4px',
      right: '-4px',
      bottom: '-4px',
      borderRadius: '12px',
      background: 'linear-gradient(45deg, #00ffff, #ff00ff)',
      filter: 'blur(8px)',
      opacity: 0.12,
      animation: 'flicker2 0.5s infinite',
      zIndex: 0,
    },
    glowEffect3: {
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      borderRadius: '10px',
      background: 'linear-gradient(45deg, #ffff00, #ff0000)',
      filter: 'blur(6px)',
      opacity: 0.1,
      animation: 'flicker3 0.3s infinite',
      zIndex: 0,
    },
  };

  return (
    <div style={styles.container}>
      <canvas id="orbs-canvas" style={styles.canvas}></canvas>
      <div style={styles.formContainerWrapper}>
        <div style={styles.glowEffect1}></div>
        <div style={styles.glowEffect2}></div>
        <div style={styles.glowEffect3}></div>
        <div style={styles.formContainer}>
          <div style={styles.title}>
            <Cpu style={{ marginRight: '8px' }} /> PostPro
          </div>
          <p style={styles.subtitle}>AI LinkedIn Content Generator</p>
          {error && <p style={styles.error}>{error}</p>}
          <form onSubmit={handleLogin} style={styles.form}>
            <div style={styles.inputGroup}>
              <User style={styles.icon} size={18} />
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={styles.input}
                required
              />
            </div>
            <div style={styles.inputGroup}>
              <Lock style={styles.icon} size={18} />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
                required
              />
            </div>
            <button type="submit" style={styles.button}>
              Start Creating
            </button>
          </form>
          <p style={styles.footer}>
            Need access? Contact your administrator.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;