import React from 'react';

function TopBar() {
  return (
    <div className="top-bar">
      <div className="user-profile">Beta Tester</div>
      <button className="upgrade-button">Sign up for Pro</button>
    </div>
  );
}

export default TopBar;