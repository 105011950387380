import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Trash2, Save, X } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../config';

export default function Posts() {
  const location = useLocation();
  const selectedPostId = location.state?.selectedPostId;

  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [saveMessage, setSaveMessage] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (selectedPostId && posts.length > 0) {
      const post = posts.find(p => p.id === selectedPostId);
      if (post) {
        handleSelectPost(post);
      }
    }
  }, [selectedPostId, posts]);

  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/posts`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const handleSelectPost = (post) => {
    setSelectedPost(post);
    setEditedContent(post.content);
  };

  const handleCancel = () => {
    setSelectedPost(null);
    setEditedContent('');
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_BASE_URL}/posts/${selectedPost.id}`, 
        { content: editedContent },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchPosts();
      setSaveMessage('Post saved successfully');
      setTimeout(() => setSaveMessage(''), 3000);
    } catch (error) {
      console.error('Error updating post:', error);
      setSaveMessage('Error saving post');
      setTimeout(() => setSaveMessage(''), 3000);
    }
  };

  const handleDelete = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_BASE_URL}/posts/${postId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchPosts();
        if (selectedPost && selectedPost.id === postId) {
          setSelectedPost(null);
          setEditedContent('');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  return (
    <div className="posts-page">
      <div className="posts-content card">
        <h1 className="page-title">Your Saved Content</h1>
        {saveMessage && <div className="save-message">{saveMessage}</div>}
        <div className="posts-layout">
          <div className="posts-list">
            {posts.map(post => (
              <div 
                key={post.id} 
                className={`post-item ${selectedPost && selectedPost.id === post.id ? 'selected' : ''}`}
                onClick={() => handleSelectPost(post)}
              >
                <p className="post-preview">{post.content.substring(0, 100)}...</p>
                <div className="post-item-footer">
                  <span className="post-date">Generated: {new Date(post.generated_at).toLocaleDateString()}</span>
                  {post.post_date && (
                    <span className="post-date">Scheduled: {new Date(post.post_date).toLocaleDateString()}</span>
                  )}
                  <button className="icon-button delete-button" onClick={(e) => { e.stopPropagation(); handleDelete(post.id); }}>
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="post-edit">
            {selectedPost ? (
              <>
                <textarea
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  rows="10"
                  className="edit-textarea"
                />
                <div className="edit-actions">
                  <button className="icon-button" onClick={handleSave}><Save size={20} /> Save</button>
                  <button className="icon-button" onClick={handleCancel}><X size={20} /> Cancel</button>
                </div>
              </>
            ) : (
              <p className="no-post-selected">Select a post to edit</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}