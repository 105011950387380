import React, { useState, useEffect, useCallback, useRef } from 'react';
import { generateLinkedInPost, refineLinkedInPost } from '../services/openai';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import { Mic, MicOff, ChevronDown, ChevronUp, ToggleLeft, ToggleRight, Upload } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { generateTopics } from '../services/topicGenerator';

function GeneratePost() {
  const [coreTopic, setCoreTopic] = useState('');
  const [keywords, setKeywords] = useState('');
  const [generatedPost, setGeneratedPost] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [language, setLanguage] = useState('EN/US');
  const [isGenerating, setIsGenerating] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [streamedContent, setStreamedContent] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [postDate, setPostDate] = useState(new Date().toISOString().split('T')[0]);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [activeInput, setActiveInput] = useState(null);
  const [topicIdeas, setTopicIdeas] = useState([]);
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null);
  const [isGeneratingTopics, setIsGeneratingTopics] = useState(false);
  const [businessInfo, setBusinessInfo] = useState('');
  const [isRefineMode, setIsRefineMode] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isSpeechRecognitionSupported, setIsSpeechRecognitionSupported] = useState(true);
  const transcriptRef = useRef('');

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploadedImage(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    }
  });

  const getLanguageCode = (lang) => {
    const languageCodes = {
      'EN/US': 'en-US',
      'EN/GB': 'en-GB',
      'FR/FR': 'fr-FR',
      'DE/DE': 'de-DE',
      'IT/IT': 'it-IT',
      'ES/ES': 'es-ES',
    };
    return languageCodes[lang] || 'en-US';
  };

  const initializeSpeechRecognition = useCallback((lang = 'EN/US') => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = getLanguageCode(lang);

      recognition.onstart = () => {
        console.log('Speech recognition started');
        transcriptRef.current = '';
      };

      recognition.onresult = (event) => {
        console.log('Speech recognition result received', event.results);
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join(' ');
        
        console.log('Transcript:', transcript);
        transcriptRef.current = transcript;
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        console.log('Speech recognition ended');
        setIsListening(false);
      };

      setRecognition(recognition);
    } else {
      setIsSpeechRecognitionSupported(false);
      console.warn('Speech recognition not supported in this browser');
    }
  }, []);

  const fetchSettings = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/settings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLanguage(response.data.language);
      setBusinessInfo(response.data.businessInfo || '');
      initializeSpeechRecognition(response.data.language);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  }, [initializeSpeechRecognition]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleGeneratePost = async () => {
    setIsGenerating(true);
    setIsLoading(true);
    setStreamedContent('');
    setIsStreaming(false);
    setGeneratedPost('');

    try {
      let postStream;

      if (isRefineMode) {
        postStream = await refineLinkedInPost(
          businessInfo,
          coreTopic,
          keywords,
          language,
          false
        );
      } else {
        postStream = await generateLinkedInPost(
          businessInfo,
          coreTopic,
          keywords,
          language,
          false
        );
      }

      // Stream the content
      setIsStreaming(true);
      let fullContent = '';
      for await (const chunk of postStream) {
        const content = chunk.choices[0]?.delta?.content || '';
        fullContent += content;
        setStreamedContent(fullContent);
      }

      setGeneratedPost(fullContent);
    } catch (error) {
      console.error('Error generating post:', error);
    } finally {
      setIsLoading(false);
      setIsGenerating(false);
      setIsStreaming(false);
    }
  };

  const handleSavePost = async () => {
    setIsSaving(true);
    try {
      const token = localStorage.getItem('token');
      const today = new Date().toISOString().split('T')[0]; // Get today's date
      await axios.post(
        `${API_BASE_URL}/posts`,
        { 
          content: generatedPost, 
          generated_at: today, // Use generated_at for today's date
          post_date: postDate // Use post_date for the scheduled date from the date picker
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSaveMessage('Post saved successfully!');
    } catch (error) {
      console.error('Error saving post:', error);
      setSaveMessage('Error saving post. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleListening = (inputField) => {
    if (!recognition) {
      console.error('Speech recognition not initialized');
      return;
    }

    if (isListening && activeInput === inputField) {
      console.log('Stopping speech recognition');
      recognition.stop();
      setIsListening(false);
      setActiveInput(null);
    } else {
      console.log('Starting speech recognition for', inputField);
      setActiveInput(inputField);
      setIsListening(true);
      if (inputField === 'coreTopic') {
        setCoreTopic(''); // Clear the coreTopic when starting a new session
        transcriptRef.current = ''; // Clear the transcriptRef as well
      }
      recognition.start();
    }
  };

  const toggleTopicExpansion = (index) => {
    setExpandedTopicIndex(prevIndex => prevIndex === index ? null : index);
  };

  const selectTopic = (topic, description) => {
    setCoreTopic(`${topic}\n\n${description}`);
    setExpandedTopicIndex(null); // Close the expanded topic after selection
  };

  const handleGenerateFAQs = async () => {
    try {
      setIsLoading(true);
      setIsGeneratingTopics(true);
      console.log('Generating FAQs with:', { businessInfo, language, imageFile: uploadedImage });
      const topics = await generateTopics(businessInfo, language);
      
      const formattedTopics = topics.map(topic => ({
        question: topic.faqTitle,
        answer: topic.discussion,
        followUpQuestion: "What has been your experience with this?",
        trendSource: topic.source,
        platform: topic.platform,
        trendDate: topic.date
      }));

      setTopicIdeas(formattedTopics);
    } catch (error) {
      console.error('Error generating FAQs:', error);
      setTopicIdeas([]);
    } finally {
      setIsGeneratingTopics(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isListening && activeInput === 'coreTopic') {
      intervalId = setInterval(() => {
        setCoreTopic(prevTopic => {
          const newTopic = transcriptRef.current.trim();
          console.log('Updated coreTopic:', newTopic);
          return newTopic;
        });
      }, 1000); // Update every second
    }
    return () => clearInterval(intervalId);
  }, [isListening, activeInput]);

  return (
    <div className="generate-post">
      <div className="generate-post-content">
        <div className="generate-form">
          <div className="card">
            <div className="scrollable-content">
              <div className="mode-toggles-container">
                <div className="mode-toggle">
                  <span>Generate</span>
                  <button
                    className="toggle-button"
                    onClick={() => setIsRefineMode(!isRefineMode)}
                    aria-label={isRefineMode ? "Switch to Generate Post mode" : "Switch to Refine Post mode"}
                  >
                    {isRefineMode ? <ToggleRight size={24} /> : <ToggleLeft size={24} />}
                  </button>
                  <span>Refine</span>
                </div>
              </div>
              
              {!isRefineMode && (
                <>
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <Upload size={24} className="upload-icon" />
                    {isDragActive ? (
                      <p className="dropzone-text active">Drop your image here!</p>
                    ) : (
                      <>
                        <p className="dropzone-text">
                          <strong>Drag & drop</strong> or <strong>click</strong> to upload an image
                        </p>
                      </>
                    )}
                    {uploadedImage && (
                      <p className="file-selected">
                        <strong>File:</strong> {uploadedImage.name}
                      </p>
                    )}
                  </div>
                  <p className="dropzone-subtext">
                    Upload an image to generate topic ideas based on its content (optional)
                  </p>
                  <button 
                    className="generate-button"
                    onClick={handleGenerateFAQs}
                    disabled={isGeneratingTopics}
                  >
                    <span>
                      {isGeneratingTopics 
                        ? 'Generating FAQs' 
                        : uploadedImage 
                          ? 'Generate FAQ Topics from Image' 
                          : 'Generate FAQ Topics'
                      }
                    </span>
                    {isGeneratingTopics && (
                      <div className="loading-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    )}
                  </button>
                </>
              )}
              
              {!isRefineMode && topicIdeas.length > 0 && (
                <div className="topic-ideas-container">
                  {topicIdeas.map((faq, index) => (
                    <div key={index} className="topic-idea-card">
                      <div 
                        className="topic-idea-header" 
                        onClick={() => toggleTopicExpansion(index)}
                      >
                        <span>{faq.question}</span>
                        {expandedTopicIndex === index ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                      </div>
                      {expandedTopicIndex === index && (
                        <div className="topic-idea-details">
                          <p>{faq.answer}</p>
                          <p><strong>Follow-up:</strong> {faq.followUpQuestion}</p>
                          {faq.trendSource && (
                            <p><strong>Source:</strong> <a href={faq.trendSource} target="_blank" rel="noopener noreferrer">{faq.trendSource}</a></p>
                          )}
                          {faq.platform && (
                            <p><strong>Platform:</strong> {faq.platform}</p>
                          )}
                          {faq.trendDate && (
                            <p><strong>Date:</strong> {faq.trendDate}</p>
                          )}
                          <button 
                            onClick={() => selectTopic(faq.question, `${faq.answer}\n\nFollow-up: ${faq.followUpQuestion}`)}
                            className="use-topic-button"
                          >
                            Use This FAQ
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              
              {!isRefineMode && <div className="topic-ideas-separator"></div>}
              
              <div>
                <label htmlFor="coreTopic">{isRefineMode ? "Refine Post" : "Core Topic"}</label>
                <div className="input-with-icon">
                  <textarea
                    id="coreTopic"
                    value={coreTopic}
                    onChange={(e) => setCoreTopic(e.target.value)}
                    placeholder={isRefineMode ? "Add your post for refinement here" : "Enter the core topic for your post"}
                    rows="4"
                  />
                  {!isRefineMode && isSpeechRecognitionSupported && (
                    <button 
                      onClick={() => toggleListening('coreTopic')} 
                      className="icon-button"
                      title={isListening && activeInput === 'coreTopic' ? "Stop voice input" : "Start voice input"}
                    >
                      {isListening && activeInput === 'coreTopic' ? 
                        <MicOff size={18} style={{ color: 'red' }} /> : 
                        <Mic size={18} style={{ color: 'black' }} />
                      }
                    </button>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="keywords">Keywords or Phrases to Include (optional)</label>
                <input 
                  id="keywords" 
                  type="text" 
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  placeholder="Enter keywords or phrases separated by commas" 
                />
              </div>
              
              <button 
                className="generate-button"
                onClick={handleGeneratePost}
                disabled={isLoading || !coreTopic}
              >
                <span>
                  {isGenerating 
                    ? 'Generating Post' 
                    : (isRefineMode ? 'Refine Post' : 'Generate Post')}
                </span>
                {isGenerating && (
                  <div className="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="generated-post">
          <div className="card">
            <h3>Generated LinkedIn Post:</h3>
            <div className="generated-post-content">
              {isStreaming ? (
                <>
                  {streamedContent}
                  <span className="blinking-cursor">|</span>
                </>
              ) : generatedPost ? (
                generatedPost
              ) : (
                <>
                  <span className="blinking-cursor">|</span>
                  Your generated post will appear here
                </>
              )}
            </div>
            {generatedPost && (
              <div className="save-post-container">
                <p className="schedule-instruction">Add to my calendar on:</p>
                <input
                  type="date"
                  value={postDate}
                  onChange={(e) => setPostDate(e.target.value)}
                  className="date-input full-width"
                />
                <button 
                  className="generate-button save-button full-width" 
                  onClick={handleSavePost}
                  disabled={isSaving}
                  style={{
                    backgroundColor: 'black',
                    color: 'white'
                  }}
                >
                  {isSaving ? 'Saving...' : 'Save Post'}
                </button>
              </div>
            )}
            {saveMessage && <div className="save-message">{saveMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneratePost;
