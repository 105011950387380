import axios from 'axios';

const PERPLEXITY_API_URL = 'https://api.perplexity.ai/chat/completions';

const generateTopics = async (businessInfo, language) => {
  try {
    const messages = [
      {
        role: "system",
        content: `You are a search expert. Your task is to find REAL customer discussions and trends in this industry.

First, analyze this business to understand the industry: ${businessInfo}

Then search for REAL customer discussions about:
1. Similar services or solutions
2. Customer needs and pain points
3. Industry trends and challenges

CRITICAL: Return ONLY a JSON array with this exact format:
[
  {
    "source": "Full URL to the discussion (e.g., https://reddit.com/r/marketing/comments/...)",
    "date": "YYYY-MM-DD",
    "discussion": "The actual discussion text",
    "faqTitle": "A concise question that summarizes this discussion",
    "platform": "Reddit/Twitter/LinkedIn/etc"
  }
]

IMPORTANT:
- source must be a complete, real URL where the discussion can be found
- Do NOT use example.com or postpro.com URLs
- Each source URL must be unique and specific to the discussion
- If exact URL unknown, use the main platform URL (e.g., https://reddit.com)`
      },
      {
        role: "user",
        content: `Find 5 real customer discussions about this industry.
Language: ${language}
Return ONLY the JSON array - no markdown, no code blocks.
Each faqTitle must be unique and specific to its discussion.
Each source must be a real, complete URL.`
      }
    ];

    const response = await axios.post(PERPLEXITY_API_URL, {
      model: "llama-3.1-sonar-small-128k-online",
      messages,
      temperature: 1,
      search_domain_filter: ["-roboquill.io"],
      return_images: false,
      return_related_questions: true,
      search_recency_filter: "month",
      presence_penalty: 0.5,
    }, {
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_PERPLEXITY_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    const content = response.data.choices[0].message.content;
    console.log("Raw Perplexity API response:", content);

    // Clean the response and parse JSON
    const cleanContent = content
      .replace(/```json\s*|\s*```/g, '') // Remove markdown code blocks
      .trim();

    try {
      const topics = JSON.parse(cleanContent);
      
      if (Array.isArray(topics) && topics.length > 0) {
        return topics.map(topic => ({
          source: topic.source || "Customer Review",
          date: topic.date || new Date().toISOString().split('T')[0],
          discussion: topic.text || topic.discussion || "",
          faqTitle: topic.faqTitle || "What do customers say about this service?",
          platform: topic.platform || "Reddit/Twitter/LinkedIn/etc"
        }));
      }
    } catch (error) {
      console.error('Failed to parse JSON:', error);
    }
    
    return [];
  } catch (error) {
    console.error('Error generating topics:', error);
    return [];
  }
};

export { generateTopics }; 