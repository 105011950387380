import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FileText, LayoutList, Calendar, Settings, LogOut, Mail, MessageSquare, Users, Cpu, Edit } from 'lucide-react';
import { jwtDecode } from "jwt-decode";

function Sidebar() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const isAdmin = token ? jwtDecode(token).is_admin : false;

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div className="logo">
          <Cpu size={24} color="black" />
          <span>PostPro</span>
        </div>
        <nav className="sidebar-nav">
          <Link to="/generate" className="nav-item">
            <FileText size={20} />
            Generate Post
          </Link>
          <Link to="/generate-blog" className="nav-item">
            <Edit size={20} />
            Generate Blog
          </Link>
          <Link to="/posts" className="nav-item">
            <LayoutList size={20} />
            Saved Content
          </Link>
          <Link to="/calendar" className="nav-item">
            <Calendar size={20} />
            Content Calendar
          </Link>
          <Link to="/settings" className="nav-item">
            <Settings size={20} />
            Settings
          </Link>
          {isAdmin ? (
            <Link to="/admin" className="nav-item">
              <Users size={20} />
              Admin Dashboard
            </Link>
          ) : null}
        </nav>
        <div className="sidebar-footer">
          <Link to="/newsletter" className="nav-item">
            <Mail size={20} />
            Newsletter
          </Link>
          <Link to="/support" className="nav-item">
            <MessageSquare size={20} />
            Support & Feedback
          </Link>
          <button onClick={handleLogout} className="nav-item logout-button">
            <LogOut size={20} />
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;